import { render, staticRenderFns } from "./ProfileCliente.vue?vue&type=template&id=e89eb11e&scoped=true"
import script from "./ProfileCliente.vue?vue&type=script&lang=js"
export * from "./ProfileCliente.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e89eb11e",
  null
  
)

export default component.exports